<template>
  <v-card :loading="loading">
    <v-toolbar>
      <v-toolbar-title>Nueva solicitud de corte</v-toolbar-title>
    </v-toolbar>

    <div class="pa-2">
      <v-autocomplete
        label="Selecciona los contratos"
        filled
        multiple
        small-chips
        :items="impagados"
        item-value="CodigoContrato"
        clearable
        deletable-chips
        :item-text="(item) => `(${item.CodigoContrato}) ${item.denominacion}`"
        v-model="selected"
        return-object
        hide-details
      ></v-autocomplete>
    </div>

    <v-divider></v-divider>

    <v-card-text style="max-height: 600px" class="pa-2">
      <v-form ref="form">
        <v-list>
          <v-list-item v-for="item in selected" :key="item.CodigoContrato">
            <v-list-item-icon class="d-flex align-center">
              <v-btn
                label
                small
                text
                :color="item.colorCliente"
                class="pa-1 font-weight-bold"
                style="min-width: unset"
                >{{ item.iniciales }}</v-btn
              >
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Contrato {{ item.CodigoContrato }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.denominacion }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="item.fechaCorteAux"
                    append-icon="mdi-calendar"
                    label="Fecha corte"
                    v-bind="attrs"
                    v-on="on"
                    filled
                    dense
                    hide-details
                    :rules="[rules.req, (v) => new Date(v).getTime() > Date.now() || 'La fecha tiene que ser superior a hoy']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="item.fechaCorteAux"
                  no-title
                  :min="today"
                ></v-date-picker>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        @click.stop="enviarSolicitud"
        :disabled="selected.length == 0"
        text
        color="error darken-1"
      >
        <v-icon left>mdi-content-cut</v-icon>
        solicitar corte
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { parseImpagados } from "../services/parseImpagados.js";
import { parseDate } from "@/utils/index.js";
import { req } from "@/utils/validations.js";

export default {
  data() {
    const d = new Date();
    const day = ("00" + d.getDate()).slice(-2);
    const month = ("00" + (d.getMonth() + 1)).slice(-2);
    const year = d.getFullYear();
    return {
      loading: false,
      impagados: [],
      selected: [],
      today: [year, month, day].join("-"),
      rules: { req },
    };
  },
  // computed: {
  //   seleccionados() {
  //     return this.selected.map((item) => {
  //       item.deuda = this.impagados
  //         .filter((imp) => imp.CodigoContrato == item.CodigoContrato)
  //         .reduce((t, c) => c.ImporteTotal + t, 0);

  //       return item;
  //     }).sort((a, b) => b.deuda - a.deuda);
  //   },
  // },
  methods: {
    parseDate,
    async getImpagados() {
      this.loading = true;
      const { data: impagados } = await axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`,
        method: "GET",
        params: {
          token: this.$store.getters.getJwtEmpresa,
          menuTab: "actual",
        },
      });
      this.impagados = parseImpagados(impagados).filter(imp => !imp.corteEn && imp.situacionContrato == 'Activado').map((imp) => {
        if (imp.fechaCorteEstimada) {
          const [day, month, year] = parseDate(
            imp.fechaCorteEstimada,
            false,
            false
          ).split(" ");
          imp.fechaCorteAux = [year, month, day].join("-");
          imp.dateAux = parseDate(imp.fechaCorteAux);
        }
        return imp;
      });

      this.loading = false;
    },
    enviarSolicitud() {
      if (!this.$refs.form.validate()) return;
      axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/solicitudesCorte.php`,
        method: "POST",
        data: {
          token: this.$store.getters.getJwtEmpresa,
          solicitudes: this.selected.map((sol) => ({
            CodigoContrato: sol.CodigoContrato,
            FechaCorte: sol.fechaCorteAux,
            anulacion: 0,
            corte: 1,
          })),
        },
      })
        .then((res) => {
          this.$root.$emit("snack", "Se ha enviado la solicitud de corte");
          this.$emit("reload");
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit(
            "snack",
            "No se ha podido enviar la solicitud de corte"
          );
        });
    },
  },
  mounted() {
    this.getImpagados();
  },
};
</script>

<style></style>
